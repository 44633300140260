import React, { useState, useEffect, useContext, createContext } from 'react';
import * as api from './api';

export const isBrowser = typeof window !== 'undefined';

export function login() {
    localStorage.setItem('redirect', window.location.href);
    window.location.href = `${process.env.GATSBY_MMC_API_URL}/connect/auth0`;
}

export function logout(path) {
    localStorage.removeItem('jwt');
    if (path = "/") {
        localStorage.setItem('redirect', window.location.origin)
    } else {
        localStorage.setItem('redirect', window.location.href);
    }

    window.location.href =
        `https://${process.env.GATSBY_AUTH0_DOMAIN}/v2/logout?` +
        new URLSearchParams({
            client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
            returnTo: `${document.location.origin}/auth/auth0/callback`,
        });
}

export async function getJwt(accessToken) {
    const response = await fetch(
        `${process.env.GATSBY_MMC_API_URL}/auth/auth0/callback?access_token=${accessToken}`,
    );
    const data = await response.json();

    localStorage.setItem('jwt', data.jwt);
}

export async function getToken() {

    const authDetails = {
        "client_id": `${process.env.GATSBY_AUTH0_CLIENT_ID}`,
        "client_secret": `${process.env.GATSBY_AUTH0_CLIENT_SECRET}`,
        "audience": "https://mymathscloud.eu.auth0.com/api/v2/",
        "grant_type": "client_credentials"
    }

    try {
        const response = await fetch(`https://mymathscloud.eu.auth0.com/oauth/token`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Accept-Encoding': 'gzip,deflate,br',
                'Connection': 'keep-alive'
            },
            method: 'POST',
            body: JSON.stringify(authDetails),
        });
        if (!response.ok) {
            const errorResData = await response.json();
        }
        const resData = await response.json();

        return resData.access_token;
    } catch (error) {
        return error;
    }
}

export const isAuthenticated = () => {
    if (!isBrowser) {
        return;
    }

    if (localStorage.getItem('jwt')) {
        return true
    } else {
        return false
    }
};

export async function createUser(userData, token) {

    try {
        const response = await fetch('https://mymathscloud.eu.auth0.com/api/v2/users', {
            headers: {
                'authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Accept-Encoding': 'gzip,deflate,br',
                'Connection': 'keep-alive',
            },
            method: 'POST',
            body: JSON.stringify(userData)
        })
        if (!response.ok) {
            const errorResData = await response.json();
        }
        const resData = await response.json();
        return resData;
    }
    catch (error) {
        return error
    }
}
