import React, { useEffect } from 'react';
import { getJwt } from '@src/utils/mmc-api/auth';
import { navigate } from 'gatsby';

const Callback = () => {
    useEffect(() => {
        (async () => {
            const accessToken = (new URLSearchParams(location.search)).get("access_token");
            if (accessToken) await getJwt(accessToken);
            else localStorage.removeItem('jwt');
            const redirect = localStorage.getItem("redirect");
            if (redirect) navigate(redirect)
            else window.location.href = "/";
        })();
    }, []);

    return null;
};

export default Callback;
